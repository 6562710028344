<template>
    <div class="component-select">
        <select v-model="comp.selected" @change="comp.update">
            <option value="">선택</option>
            <option :value="v" v-for="(v, i) in comp.list" :key="i">{{ v }}</option>
        </select>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotIndustry',
    props: {
        modelValue: {
            type: String,
            default: "",
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const comp = reactive({
            selected : "",
            list     : [],

            doSearch: () => {
                axios.get("/rest/component/getCustomerList", { params: {} }).then((res) => {
                    comp.list = res.data;
                });
            },

            update: () => {
                emit('update:modelValue', comp.selected);
            }

        });

        onMounted(() => {
            // Mounted
            comp.selected = props.modelValue;

            comp.doSearch();
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
    .component-select {
        display: inline-block;
        select {
            width: 100%;
        }
    }
</style>