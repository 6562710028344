<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-700px">
            <div>
                <p class="title">고객사 단계 구분표</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view stageClass-table">
                    <tbody>
                        <tr>
                            <th width="120">0단계</th>
                            <td>
                                <div class="txt1">Quit 고객사 : 서비스 종료(1차수) 고객사</div>
                                <div class="txt2">고객 관리자 : Main (사업부 담당 리드) / Sub (사업리더OL / 전략 마케팅)</div>
                            </td>
                        </tr>
                        <tr>
                            <th width="120">1단계</th>
                            <td>
                                <div class="txt1">현재 고객사 : 서비스 중 고객사</div>
                                <div class="txt2">고객 관리자 : Main (사업부 담당 파트장) / Sub (사업부 담당 리드)</div>
                            </td>
                        </tr>
                        <tr>
                            <th width="120">2단계</th>
                            <td>
                                <div class="txt1">미팅 고객사 : 1회 이상 방문 미팅 고객사</div>
                                <div class="txt2">고객 관리자 : Main (마케팅팀)</div>
                            </td>
                        </tr>
                        <tr>
                            <th width="120">3단계</th>
                            <td>
                                <div class="txt1">일반 고객사 : 마케팅실 신규 발굴 컨택 고객사</div>
                                <div class="txt2">고객 관리자 : Main (마케팅팀)</div>
                            </td>
                        </tr>
                        <tr>
                            <th width="120">9단계</th>
                            <td>
                                <div class="txt1">지원 제휴사</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'CustomerStageTablePopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>