<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CustomerStageTablePopup v-if="pop.isShowStep" @close="pop.isShowStep=false"></CustomerStageTablePopup>
        <TypeIndustryPopup v-if="pop.isShowType" @close="pop.isShowType=false"></TypeIndustryPopup>

        <CarrotTitle title="고객사(DB)/상담일지">
            <div class="btn-ibbox title-btnbox float-right">


              <router-link :to="{ name:'customerManagement-customerStatus' }"><button class="btn-default  ml-5">단계별 고객 관리 현황</button></router-link>
                <button class="btn-default ml-5" @click="pop.isShowType=true">고객사 유형/산업군</button>
                <button class="btn-default ml-5" @click="pop.isShowStep=true">고객사 단계 구분표</button>
            </div>
            - 고객사 0단계 및 1단계는 CTM, ITM에 해당 고객사가 수업 종료 혹은 수업 중일 경우 자동으로 설정됩니다.<br>
            - 고객사 등록 시 단계 설정을 하지 않으면 자동으로 3단계로 설정되며, 고객사일지가 작성되면 자동으로 2단계로 설정됩니다.<br>
            - 단계는 매일 자정(am00:00)에 업데이트 됩니다.<br>
            - 최근 컨택 매니저, 최근 컨택일, 업체담당자, 컨택빈도는 해당 고객사의 최근 작성된 고객사일지를 기준으로 노출됩니다.<br>
            - 컨택빈도 : <span class="color-1">● 3개월이내 컨택이력 없음</span> / <span class="color-2">● 3개월이내 컨택이력 있음</span> / <span class="color-3">● 한달 이내 컨택이력 있음</span>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <table class="table-row table-serach">
                    <tbody>
                        <tr>
                            <th width="180">고객사명</th>
                            <td>
                                <input type="text" class="w-90per" v-model="bbs.cname" @keyup.enter="bbs.doInit">
                            </td>
                            <th width="180">본부/팀</th>
                            <td>
                                <carrot-dept v-model="bbs.office" class="w-45per"></carrot-dept>
                                <carrot-team :idx_office="bbs.office" v-model="bbs.team" class="w-45per"></carrot-team>
                            </td>
                        </tr>
                        <tr>
                            <th width="180">고객사 단계</th>
                            <td>
                                <select v-model="bbs.step" class="w-90per">
                                    <option value="">선택</option>
                                    <option value="0">0단계(Quit고객)</option>
                                    <option value="1">1단계(현재고객사)</option>
                                    <option value="2">2단계(미팅고객사)</option>
                                    <option value="3">3단계(일반고객사)</option>
                                    <option value="9">9단계(지원제휴사)</option>
                                </select>
                            </td>
                            <th width="180">고객사 유형</th>
                            <td>
                                <carrot-company-type v-model="bbs.ctype" class="w-90per"></carrot-company-type>
                            </td>
                        </tr>
                        <tr>
                            <th width="180">고객사 산업군</th>
                            <td>
                                <carrot-industry v-model="bbs.itype" class="w-90per"></carrot-industry>
                            </td>
                            <th width="180">주소(지역)</th>
                            <td>
                                <input type="text" v-model="bbs.addr" class="w-90per" @keyup.enter="bbs.doInit">
                            </td>
                        </tr>
                        <tr>
                            <th width="180">최근 컨택 매니저</th>
                            <td>
                                <carrot-search-staff v-model="bbs.contact" class="w-90per"></carrot-search-staff>
                            </td>
                            <th width="180">컨택빈도</th>
                            <td>
                                <select v-model="bbs.contact_type" class="w-90per">
                                    <option value="">선택</option>
                                    <option value="3">한달 이내 컨택이력 있음</option>
                                    <option value="2">3개월 이내 컨택이력 있음</option>
                                    <option value="1">3개월 이내 컨택이력 없음</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn-default float-right mt-10" @click="bbs.doInit">검색</button>
                <div class="clear"></div>
            </div>
            <div class="mt-40">
                <div>
                    <div class="mt-50 mb-20">
                        <router-link :to="{ name:'customerManagement-customerDBAdd' }"><button class="btn-default float-right ml-5">고객사 등록</button></router-link>
                        <button v-if="bbs.isAuth==true" class="btn-default float-right" @click="bbs.downExcel">엑셀 다운로드</button>
                        <div class="clear"></div>
                    </div>
                    <table class="table-col">
                        <colgroup>
                            <col width="60">
                            <col width="*">
                            <col width="100">
                            <col width="200">
                            <col width="110">
                            <col width="160">
                            <col width="140">
                            <col width="100">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>고객사명</th>
                                <th>고객사 단계</th>
                                <th>최근 컨택 매니저</th>
                                <th>최근 컨택일</th>
                                <th>업체 담당자</th>
                                <th>담당자 연락처</th>
                                <th>컨택빈도</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td class="txt-left"><router-link :to="{ name:'customerManagement-customerDBViewTab1-idx', params:{ idx:irow.idx } }" target="_blank"><span class="btn-view">{{ irow.kname }}</span> <span v-if="bbs.chkNewBtn(irow.contact_date)" class="my-box-list-new">N</span></router-link></td>
                                <td>{{ irow.step?irow.step:'-' }}</td>
                                <td>{{ irow.hq_ename?irow.hq_ename + '(' + irow.hq_kname + ')':'-' }}</td>
                                <td>{{ irow.contact_date?irow.contact_date:'-' }}</td>
                                <td>{{ irow.mgr_kname?irow.mgr_kname:'-' }}</td>
                                <td>{{ irow.mgr_mobile?irow.mgr_mobile:'-' }}</td>
                                <td>
                                    <span v-if="1==irow.contact_type" class="color-1">●</span>
                                    <span v-if="2==irow.contact_type" class="color-2">●</span>
                                    <span v-if="3==irow.contact_type" class="color-3">●</span>
                                </td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="8">DB 정보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging v-if="bbs.isLoaded==true" :total="bbs.total" :list_per_page="bbs.rows" v-model.sync="bbs.page" @change="bbs.doSearch"></CarrotPaging>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotCompanyType from '@/components/common/CarrotCompanyType.vue'
import CarrotIndustry from '@/components/common/CarrotIndustry.vue'
import CarrotSearchStaff from '@/components/common/CarrotSearchStaff.vue'
import TypeIndustryPopup from '@/components/popup/customerManagement/TypeIndustryPopup.vue'
import CustomerStageTablePopup from '@/components/popup/customerManagement/CustomerStageTablePopup.vue'


export default {
    layout:"customerManagement",
    components: {
        CarrotDept,
        CarrotTeam,
        CarrotCompanyType,
        CarrotIndustry,
        CarrotSearchStaff,
        TypeIndustryPopup,
        CustomerStageTablePopup,
    },
    setup() {
        const toast = useToast()

        const pop = reactive({
            // Popup 노출 여부
            isShowType : false,
            isShowStep : false,
        });

        const bbs = reactive({
            isLoaded : false,
            isAuth   : false,

            page : 1,
            rows : 50,

            cname   : "",            // 고객사 명
            office  : "0",            // hq office
            team    : "0",            // hq team
            step    : "",            // 고객사 단계
            ctype   : "",            // company type
            itype   : "",            // industry type
            addr    : "",            // 주소(지역)
            contact : "",            // 최근 컨택 매니저
            contact_type : "",       // 컨택빈도

            list : [], total : 0,

            downExcel : () => {
                let url = "https://api.carrotians.net" + "/excel/customer_db_list"
                        + "?cname="  + bbs.cname 
                        + "&office=" + bbs.office 
                        + "&team="   + bbs.team 
                        + "&step="   + bbs.step 
                        + "&ctype="  + bbs.ctype
                        + "&itype="  + bbs.itype
                        + "&addr="   + bbs.addr
                        + "&contact="      + bbs.contact
                        + "&contact_type=" + bbs.contact_type;
                window.open(url, '_blank').focus();
            },

            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
            },

            doSearch : () => {
                let params = {
                    page : bbs.page,
                    rows : bbs.rows,
                    cname  : bbs.cname,
                    office : bbs.office,
                    team   : bbs.team,
                    step   : bbs.step,
                    ctype  : bbs.ctype,
                    itype  : bbs.itype,
                    addr   : bbs.addr,
                    idx_hq : bbs.contact,
                    contact_type : bbs.contact_type
                };

                axios.get("/rest/customermgr/getCustomerDBList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.isAuth = res.data.is_auth=='Y'?true:false;

                        bbs.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            chkNewBtn : (date) => {
                var nowDate = new Date();
                var tmpDate = new Date(date);
                if(nowDate - tmpDate < 86400000 ) return true;
                else return false;
            },
        });

        onMounted(() => {
            // Mounted
            bbs.doSearch();
        });

        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>