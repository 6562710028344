<template>
    <div class="comp-select">
        <input type="text" v-model.trim="comp.title" @keydown="comp.doSearch" @keyup="comp.doSearch" class="input-txt" placeholder="이름 입력 후 선택해주세요.">
        <div v-if="comp.selected==false" class="comp-list">
            <template v-for="(irow, i) in comp.list" :key="i">
                <div @click="comp.doSelect(irow)" class="comp-item">
                    {{ irow.title_s }}<span class="red">{{ irow.title_m }}</span>{{ irow.title_e }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotSearchStaff',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        idx_office: {
            type: [Number, String],
            default: 0,
        },
        idx_team: {
            type: [Number, String],
            default: 0,
        },
        kname: {
            type: String,
            default: ""
        },
        ename: {
            type: String,
            default: ""
        },
        orderby:{
            type: String,
            default: "easc"
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {

        const comp = reactive({
            selected   : true,
            staff      : [],
            list       : [],

            idx_office : 0,
            idx_team   : 0,
            idx_staff  : 0,
            ename      : "",
            kname      : "",
            title      : "",
            orderby    : "easc",

            doSearch : () => {
                if( comp.title.length < 2 ){
                    comp.list  = [];
                    comp.total = 0;
                    comp.selected = true;
                    return;
                }

                let title = comp.title.toLowerCase();

                comp.list = [];
                for(let i=0; i<comp.staff.length; i++){
                    let irow = comp.staff[i];

                    let check = false;
                    if(comp.idx_team>0 && (irow.idx_office==comp.idx_team || irow.idx_team==comp.idx_team)) {
                        check = true;
                    } else if(comp.idx_team==0 && comp.idx_office>0 && (irow.idx_office==comp.idx_office || irow.idx_team==comp.idx_office)) {
                        check = true;
                    } else if(comp.idx_office == 0 && comp.idx_team == 0) {
                        check = true;
                    }

                    let staffname = irow.ename.toLowerCase() + ' (' + irow.kname + ')';
                    irow.title    = staffname;
                    let s = staffname.indexOf(title);
                    let l = title.length;
                    if( check == true && s >= 0 ) check = true;
                    else check = false;

                    if( check == false ) continue;

                    irow.title_s = irow.title.substr(0, s);
                    irow.title_m = irow.title.substr(s, l);
                    irow.title_e = irow.title.substr(s+l, staffname.length-(s+l));


                    comp.list.push(irow);
                }

                if( comp.list.length > 0 ) comp.selected = false;
            },

            getStaff : () => {
                let params = {
                    idx_office : 0,
                    idx_team : 0,
                    orderby : comp.orderby
                };
                axios.get("/rest/component/getStaff", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.staff = res.data.list;
                    } else {
                        console.info(res.data);
                    }
                });
            },

            doSelect: (staff) => {
                comp.idx_staff = staff.idx;

                comp.ename = staff.ename;
                comp.kname = staff.kname;
                comp.title = staff.ename + ' (' + staff.kname + ')';

                emit('update:ename', comp.ename);
                emit('update:kname', comp.kname);
                emit('update:modelValue', comp.idx_staff);
                comp.selected = true;
            }
        });

        watch(() => props.idx_office, (value, preValue) => {
            if(preValue!=value) {
                comp.idx_office = value;
                comp.idx_team   = 0;
                comp.idx_staff   = 0;
                emit('update:modelValue', comp.idx_staff);
            }
        });
        watch(() => props.idx_team, (value, preValue) => {
            console.info(value);
            if(preValue!=value) {
                comp.idx_team = value;
                comp.idx_staff   = 0;
                emit('update:modelValue', comp.idx_staff);
            }
        });

        onMounted(() => {
            // Mounted
            comp.idx_office = Number(props.idx_office);
            comp.idx_team   = Number(props.modelValue);
            comp.orderby    = props.orderby;

            comp.getStaff();
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
.comp-select {
    position:relative; display: inline-block; height:30px;
    .input-txt {
        width: 100%; height:30px; vertical-align: top;
    }
    .comp-list {
        position: absolute; top:30px; left:0; width:100%; max-height:200px; overflow:hidden; overflow-y:auto; background-color:#fff; border:1px solid #c8c8c8; padding:5px 8px; z-index: 2;
        .comp-item {
            line-height:30px; cursor: pointer; border-bottom:1px solid #c8c8c8; white-space: nowrap;
            &:hover {
                background-color: #efefef;
            }
            &:last-child {
                border-bottom-width: 0px;
            }
            .red {
                color:#ff854a;
            }
        }
    }
}
</style>