<template>
  <div class="carrot-layer-bg">
    <div class="carrot-layer w-700px">
      <div>
        <p class="title">유형/산업군</p>
        <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
      </div>
      <div class="mt-10 tbl-light">
        <table class="tbl-light-list">
          <colgroup>
            <col width="70" />
            <col width="*" />
            <col width="310" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>현재</th>
              <th>변경</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>유형</td>
              <td>대기업 / 외국계기업 / 공기업 / 정부지자체 / 중소기업 / 병원 / 학교 / 기타</td>
              <td>대기업 / 외국계기업 / 중견기업 / 공기업 / 정부 및 지자체 / 중소기업벤처기업 / 공공기관 / 병원 / 학교 / 기타</td>
            </tr>
            <tr>
              <td>산업군</td>
              <td>전자/통신/반도체, 건설/화학, 기계/철강/자동차, 에너지/환경, 패션/무역/유통, 의료/제약, 서비스/교육/금융, IT/인터넷, 공공부문, 생산/제조, 언론, 기타</td>
              <td>
                제조업 / 건설업 / 도매 및 소매업 / 정보통신업 / 금융 및 보험업 / 전문과학 및 기술 서비스업 / 교육 서비스업 / 보건업 및 사회복지 서비스업 / 예술, 스포츠 및 여가관련 서비스업 / 국제 및
                외국기관 / 공공 행정, 국방 및 사회보장 행정 / 기타
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "TypeIndustryPopup",
  props: {
    idx: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const pop = reactive({
      onClose: () => {
        emit("close");
      },
    });

    return { pop };
  },
};
</script>
<style lang="scss" scoped></style>
